<template>
	<div>
		<div
			class="form-check form-switch"
			v-if="inputConfig.optional && !inputConfig.optional_subscribe"
		>
			<input
				class="form-check-input"
				type="checkbox"
				role="switch"
				:id="`input_${id}_option`"
				@change="
					optionalValue = inputConfig.optionalValueOnFalse
						? !$event.target.checked
						: $event.target.checked;
					emit();
					vuelidateItem.$touch();
				"
				:checked="
					inputConfig.optionalValueOnFalse
						? !optionalValue
						: optionalValue
				"
				:disabled="disabled"
			/>
			<label class="form-check-label" :for="`input_${id}_option`">{{
				inputConfig.optional
			}}</label>
		</div>
		<div
			:style="
				inputConfig.optional
					? 'padding-left: 2.5em; margin-top: -0.2em;'
					: ''
			"
			v-if="!inputConfig.optional || optionalValue"
		>
			<h5 v-if="inputConfig?.heading">{{ inputConfig.heading }}</h5>

			<!-- search and select -->
			<div v-if="inputConfig.type == 'select_single_search'">
				<!-- <p v-if="inputConfig.label">{{ inputConfig.label }}</p> -->
				<label
					v-if="inputConfig.showLabel"
					:for="`input_${id}`"
					class="form-label mb-0"
				>
					{{ inputConfig.label }}:
				</label>
				<model-list-select
					v-model="inputValue"
					:list="optionsList"
					option-value="value"
					option-text="label"
					:id="`input_${id}`"
					:placeholder="placeholderValue || 'Select'"
					:is-error="vuelidateItem.$dirty && vuelidateItem.$error"
					@searchchange="
						searchQuery =>
							inputConfig?.axiosSearchSelectOptions?.url
								? getOptions(searchQuery)
								: () => {}
					"
					:isDisabled="disabled"
					@click.once="selectSingleSearchFirstFocus"
					:filterPredicate="
						inputConfig?.axiosSearchSelectOptions
							?.filterPredicate === false
							? () => true
							: inputConfig?.axiosSearchSelectOptions
									?.filterPredicate
					"
				>
				</model-list-select>
				<input-feedback
					:componentsErrors="vuelidateItem.$errors"
					:label="inputConfig.label"
				/>
			</div>

			<!-- Multi button selection OR button Single selection -->
			<div
				v-else-if="
					inputConfig.type == 'select_multi_button' ||
					inputConfig.type == 'select_single_button'
				"
				class="my-1"
			>
				<label v-if="inputConfig.showLabel" class="form-label mb-0"
					>{{ inputConfig.label }}:</label
				>
				<!-- <p class="fst-italic" v-if="inputConfig.label">{{ inputConfig.label }}</p> -->
				<div class="btn-group">
					<template
						v-for="(option, index) in optionsList"
						:key="option?.value ?? option"
					>
						<input
							:class="`btn-check ${
								vuelidateItem.$dirty &&
								(vuelidateItem.$error
									? 'is-invalid'
									: 'is-valid')
							}`"
							:type="
								inputConfig.type == 'select_multi_button'
									? 'checkbox'
									: 'radio'
							"
							:value="option?.value ?? option"
							:id="`input_${id}_${index}`"
							v-model="inputValue"
							:disabled="disabled"
						/>
						<label
							:class="{
								btn: true,
								'btn-primary': !option?.label?.image,
								'btn-icon': option?.label?.image,
							}"
							:for="`input_${id}_${index}`"
						>
							<img
								style="
									max-width: 50px;
									max-height: 50px;
									width: 50px;
								"
								v-if="option?.label?.image"
								:src="option.label.image"
							/>
							<template v-else>{{
								option?.label ?? option
							}}</template>
						</label>
					</template>
				</div>
				<input-feedback
					:componentsErrors="vuelidateItem.$errors"
					:label="inputConfig.label"
				/>
			</div>

			<!-- Multi selection OR short Single selection -->
			<div
				v-else-if="
					inputConfig.type == 'select_multi' ||
					(inputConfig.type == 'select_single' &&
						optionsList?.length <= 2 &&
						smartSelects)
				"
			>
				<label v-if="inputConfig.showLabel" class="form-label mb-0"
					>{{ inputConfig.label }}:</label
				>
				<!-- <p class="fst-italic" v-if="inputConfig.label">{{ inputConfig.label }}</p> -->
				<div
					class="form-check"
					v-for="(option, index) in optionsList"
					:key="option?.value ?? option"
				>
					<input
						:class="`form-check-input ${
							vuelidateItem.$dirty &&
							(vuelidateItem.$error ? 'is-invalid' : 'is-valid')
						}`"
						:type="
							inputConfig.type == 'select_multi'
								? 'checkbox'
								: 'radio'
						"
						:value="option?.value ?? option"
						:id="`input_${id}_${index}`"
						v-model="inputValue"
						:disabled="option?.disabled || disabled"
					/>
					<label
						class="form-check-label"
						:for="`input_${id}_${index}`"
					>
						{{ option?.label ?? option }}
					</label>
				</div>
				<input-feedback
					:componentsErrors="vuelidateItem.$errors"
					:label="inputConfig.label"
				/>
			</div>

			<!-- long Single selection -->
			<div
				v-else-if="
					inputConfig.type == 'select_single' &&
					((smartSelects && optionsList?.length > 2) || true)
				"
				:class="{
					'form-floating': inputConfig.showLabel ?? true,
				}"
			>
				<!-- <p v-if="inputConfig.label">{{ inputConfig.label }}</p> -->
				<select
					v-model="inputValue"
					:class="`form-select form-select-sm ${
						vuelidateItem.$dirty &&
						(vuelidateItem.$error ? 'is-invalid' : 'is-valid')
					}`"
					:id="`input_${id}`"
					:placeholder="placeholderValue || 'Select'"
					:disabled="disabled"
				>
					<option
						v-for="option in optionsList"
						:value="option?.value ?? option"
						:key="option?.value ?? option"
						:disabled="option?.disabled"
					>
						{{ option?.label ?? option }}
					</option>
				</select>
				<label
					v-if="inputConfig.showLabel ?? true"
					:for="`input_${id}`"
					class="form-label"
					>{{ inputConfig.label }}</label
				>
				<input-feedback
					:componentsErrors="vuelidateItem.$errors"
					:label="inputConfig.label"
				/>
			</div>

			<!-- Textarea -->
			<div
				:class="{
					'form-floating': inputConfig.showLabel ?? true,
				}"
				v-else-if="inputConfig.type == 'textarea'"
			>
				<textarea
					:id="`input_${id}`"
					:class="`form-control form-control-sm ${
						vuelidateItem.$dirty &&
						(vuelidateItem.$error ? 'is-invalid' : 'is-valid')
					}`"
					style="height: 9rem"
					v-model="inputValue"
					:placeholder="placeholderValue"
					:disabled="disabled"
				></textarea>
				<label
					v-if="inputConfig.showLabel ?? true"
					:for="`input_${id}`"
					class="form-label"
					>{{ inputConfig.label }}</label
				>
				<input-feedback
					:componentsErrors="vuelidateItem.$errors"
					:label="inputConfig.label"
				/>
			</div>

			<!-- Address -->
			<div
				class="form-floating"
				v-else-if="inputConfig.type == 'address'"
			>
				<address-input
					v-model="inputValue"
					:id="`input_${id}`"
					:placeholder="placeholderValue"
					:disabled="disabled"
					:validationScope="validationScope"
					:searchTypes="inputConfig.searchTypes"
					:countryRestriction="inputConfig.countryRestriction"
					type="address"
				/>
			</div>

			<!-- Location -->
			<div
				class="form-floating"
				v-else-if="inputConfig.type == 'location'"
			>
				<address-input
					v-model="inputValue"
					:id="`input_${id}`"
					:placeholder="placeholderValue"
					:disabled="disabled"
					:validationScope="false"
					:searchTypes="['locality', 'sublocality']"
					:countryRestriction="inputConfig.countryRestriction"
					:notExpanded="true"
					type="location"
				/>
			</div>

			<!-- Range input -->
			<div v-else-if="inputConfig.type == 'range'" class="my-1">
				<label
					v-if="inputConfig.showLabel || true"
					:for="`input_${id}`"
					class="form-label mb-0"
				>
					{{ inputConfig.label }}: {{ inputValue }}
				</label>
				<input
					:id="`input_${id}`"
					:type="inputConfig.type"
					:class="`form-range ${
						vuelidateItem.$dirty &&
						(vuelidateItem.$error ? 'is-invalid' : 'is-valid')
					}`"
					v-model="inputValue"
					:disabled="disabled"
					:="inputConfig.applyProps"
				/>
				<input-feedback
					:componentsErrors="vuelidateItem.$errors"
					:label="inputConfig.label"
				/>
			</div>

			<!-- Switch input -->
			<div
				v-else-if="inputConfig.type == 'switch'"
				class="my-1 form-check form-switch"
			>
				<input
					class="form-check-input"
					:class="`form-check-input ${
						vuelidateItem.$dirty &&
						(vuelidateItem.$error ? 'is-invalid' : 'is-valid')
					}`"
					type="checkbox"
					role="switch"
					:id="`input_${id}`"
					v-model="inputValue"
					:disabled="disabled"
				/>
				<label class="form-check-label" :for="`input_${id}`">
					<a
						v-if="inputConfig.label?.link"
						:href="inputConfig.label?.link"
						target="_blank"
					>
						{{ inputConfig.label?.text }}
					</a>
					<template v-else>{{ inputConfig.label }}</template>
				</label>
				<input-feedback
					:componentsErrors="vuelidateItem.$errors"
					:label="inputConfig.label?.text ?? inputConfig.label"
				/>
			</div>

			<!-- Standard input -->
			<div
				:class="{
					'form-floating': inputConfig.showLabel ?? true,
				}"
				v-else
			>
				<input
					:id="`input_${id}`"
					:type="inputConfig.type"
					:class="`form-control form-control-sm ${
						vuelidateItem.$dirty &&
						(vuelidateItem.$error ? 'is-invalid' : 'is-valid')
					}`"
					v-model="inputValue"
					:placeholder="placeholderValue"
					:disabled="disabled"
					:="inputConfig.applyProps"
				/>
				<label
					v-if="inputConfig.showLabel ?? true"
					:for="`input_${id}`"
					class="form-label"
				>
					<i
						v-if="inputConfig.label == 'Search'"
						class="fa-solid fa-magnifying-glass"
					></i>
					{{ inputConfig.label }}
				</label>
				<input-feedback
					:componentsErrors="vuelidateItem.$errors"
					:label="inputConfig.label"
				/>
			</div>
		</div>
	</div>
</template>
<script>
/*

Use it as though it is any other input field. Pass in inputConfig and v-model as normal.

inputConfig: {
	key: unique identifier
	label: label for the input field
	type:
		select_single -> renders radio inputs
		select_single_search -> renders vue-search-select
		select_multi -> upto 2 options renders radio inputs else renders select
		select_[option]_button -> (option as above 2) renders radio/checkbox buttons
		textarea -> renders textarea
		address -> renders address input
		location -> renders address input setup to only show cities
		[other] -> renders <input type="[other]">
	?applyProps: object of props to be applied to a [other] input
	?options: array of options for select_single and select_multi as [{value, label, disabled}]
	?optional: string with question to ask prior to displaying input. "Do you have a GST number?" before entering gst number.
	?optional_subscribe: is optional based off another input from JSONs optional value, set to the other inputs key
	?optionalValueOnFalse: default shows input if switch on, this shows input if switch off
	?validations: {[validation]: [value]}
		[validation]:
			custom -> points to a custom validation referenced within the allCustomValidators.js file
			length -> appends both minLength and maxLength validators of the given [value]
			[other] -> selects [other] vuelidate helper
		[value]: where (value === true) [validation] will be applied as an object. Otherwise it will be assumed [validation] is a function and will be applied as [validation]([value]).
	?heading: if passed string will show a h5 heading above the input field with the given value
	?showLabel: (for select fields) if should show the label prop (by default select fields do not)
	?axiosSearchSelectOptions: if using a select type and want the options generated from axios
		config object with:
			url: must be a POST endpoint that return the result of a FilterTable with a search filter option
			getOnEmpty (defaults false): true/false <- if should get an options list when search query is empty (if there are a lot of options, false will require them to search)
			getFirstOn (defaults mounted): false/mounted/focus <- when it should first load the options list
			?urlDataKey: key of axios.response.data that contains the array of items (defaults to response.data.items)
			?dataQuery: addition properties to append to the query params
			?dataCallback: if endpoint doesn't return an array, use callback to convert response.data to array of options
			?labelKey: key of the response items to use for the option label (defaults to .label)
				Can also be array of keys or callback function (passed item Object return String)
			?valueKey: key of the response items to use for the option value (defaults to .value)
				Can also be array of keys or callback function (passed item Object return String)
			?disabledKey: key of the response items to use for if the option is disabled (defaults to .disabled)
				Can also be callback function (passed item Object return Boolean)
			?defaultFilters: default filters to apply to the axios request
			?filterPredicate:
				false <- do no use filter options (only use axios search for filtering)
				(label, searchQuery) => true/false if should show option in list
	?searchTypes: passthrough for address or location inputs
	?countryRestriction: passthrough for address or location inputs
}

*/

import InputFeedback from "./InputFeedback.vue";
import { useVuelidate } from "@vuelidate/core";
import * as VuelidateValidators from "@vuelidate/validators";
import allCustomValidators from "../../customValidators/allCustomValidators";
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import axios from "axios";
import { useAppStore } from "../../store/app";
import AddressInput from "./AddressInput.vue";

const typeToDefaultValue = type => {
	switch (type) {
		case "select_multi":
			return [];
		case "select_multi_button":
			return [];
		default:
			return "";
	}
};

export default {
	components: { InputFeedback, ModelListSelect, AddressInput },
	name: "input-from-json",
	props: [
		"inputConfig",
		"modelValue",
		"validationScope",
		"disabled",
		"placeholder",
		"vuelidateInstance",
		"smartSelects", //if selects should change their type based off number of options (default true, if false only)
	],
	emits: ["update:modelValue", "valueChanged"],
	expose: ["getAxiosOptions", "optionalValue", "optionsList"],
	watch: {
		// inputConfig(nv) {
		// 	console.log(nv);
		// },
		inputValue() {
			this.preventAxiosChange();
			this.emit();
		},
		modelValue(mv) {
			if (
				(!mv && !this.optionalValue && !!this.inputConfig?.optional) ||
				(!mv &&
					this.inputValue ==
						typeToDefaultValue(this.inputConfig.type)) ||
				this.inputValue == mv
			)
				return;
			this.inputValue = mv || typeToDefaultValue(this.inputConfig.type);
			this.optionalValue = !!mv;
			this.v$.$reset();
		},
	},
	validations() {
		if (this.validationsHelper.data) {
			//check for change in optionalValue or inputConfig
			const optionalChange =
				this.optionalValue != this.validationsHelper.optionalValue;
			const configChange =
				JSON.stringify(Object.values(this.inputConfig)) !=
				this.validationsHelper.inputConfig;
			if (!optionalChange && !configChange)
				return this.validationsHelper.data;
		}

		const set = vs => {
			this.validationsHelper.data = vs;
			this.validationsHelper.optionalValue = this.optionalValue;
			this.validationsHelper.inputConfig = JSON.stringify(
				Object.values(this.inputConfig),
			);
			return vs;
		};

		//if not validations
		if (!this.isValidated) return set({ validatedInputValue: {} });

		//convert inputConfig.validations into valid vuelidate
		const validations = {};
		if (!this.inputConfig.optional || this.optionalValue)
			validations.required = VuelidateValidators.required;

		return set({
			validatedInputValue: {
				...validations,
				...this.dynamicValidations,
			},
		});
	},
	data() {
		const appStore = useAppStore();

		appStore.setData(
			`input-from-json:${this.inputConfig.key}:optional-value`,
			!!this.modelValue,
		);

		//init model value
		let inputValue;
		if (this.modelValue) inputValue = this.modelValue;
		else inputValue = typeToDefaultValue(this.inputConfig.type);

		return {
			inputValue,
			fetchedOptions: [],
			timeout: null,
			//fallback placeholder to label
			placeholderValue:
				this.placeholder ||
				this.inputConfig.placeholder ||
				this.inputConfig.label ||
				"",
			providedOptions: [],
			appStore,
		};
	},
	setup(props) {
		return {
			v$: useVuelidate({
				$autoDirty: props.inputConfig?.validations !== false,
				$scope: props.validationScope,
			}),
			mounttime: Date.now(),
			id: Math.floor(Math.random() * 1000 * Date.now()),
			validationsHelper: {
				data: null,
			},
		};
	},
	mounted() {
		//init the list if required
		const getFirstOn =
			this.inputConfig?.axiosSearchSelectOptions?.getFirstOn ?? "mounted";
		if (
			getFirstOn == "mounted" &&
			this.inputConfig?.axiosSearchSelectOptions?.url
		)
			this.getOptions("");
	},
	unmounted() {
		this.appStore.clearData(
			`input-from-json:${this.inputConfig.key}:optional-value`,
		);
	},
	methods: {
		preventAxiosChange() {
			//prevent the prevention within 200ms of mount
			if (Date.now() - this.mounttime < 200) return;

			this.blockAxios = true;
			setTimeout(() => (this.blockAxios = false), 500);
		},
		selectSingleSearchFirstFocus() {
			const getOnFocus =
				this.inputConfig?.axiosSearchSelectOptions?.getFirstOn ==
				"focus";
			if (getOnFocus) this.getAxiosOptions("");
		},
		getOptions(searchQuery) {
			if (this.blockAxios) return;

			const getOnEmpty =
				this.inputConfig?.axiosSearchSelectOptions?.getOnEmpty ?? false;
			if (!searchQuery && !getOnEmpty) {
				//clear options
				this.fetchedOptions.splice(0, this.fetchedOptions.length);
				return;
			}
			//get axios options on 200ms timeout
			clearTimeout(this.timeout);
			this.timeout = setTimeout(
				() => this.getAxiosOptions(searchQuery),
				200,
			);
		},
		async getAxiosOptions(searchQuery) {
			if (this.blockAxios) return;
			if (this.disabled) return;

			//prep
			const url = this.inputConfig.axiosSearchSelectOptions?.url;
			const urlDataKey =
				this.inputConfig.axiosSearchSelectOptions?.urlDataKey;
			const dataQuery =
				this.inputConfig.axiosSearchSelectOptions?.dataQuery ?? {};
			const dataCallback =
				this.inputConfig.axiosSearchSelectOptions?.dataCallback;
			const labelKey =
				this.inputConfig.axiosSearchSelectOptions?.labelKey ?? "label";
			const valueKey =
				this.inputConfig.axiosSearchSelectOptions?.valueKey ?? "value";
			const disabledKey =
				this.inputConfig.axiosSearchSelectOptions?.disabledKey ??
				"disabled";

			//get from axios
			let newData;
			try {
				const response = await axios.get(url, {
					params: {
						...dataQuery,
						filters: JSON.stringify({
							search: searchQuery,
							...(this.inputConfig?.axiosSearchSelectOptions
								?.defaultFilters ?? {}),
						}),
					},
				});

				newData = dataCallback
					? dataCallback(response.data)
					: urlDataKey
					? response.data[urlDataKey]
					: response.data.items;
			} catch (err) {
				console.error(err);

				//no new data
				newData = [];
			}

			//append data
			//clear current options
			this.fetchedOptions.splice(0, this.fetchedOptions.length);

			//prep, deduplicate and append new options
			const newOptions = newData
				.map(option => {
					const get = key => {
						switch (typeof key) {
							case "function":
								return key(option);
							case "object": //Array to use multiple
								return key.map(k => option[k]).join(" ");
							default:
								return option[key];
						}
					};

					return {
						label: get(labelKey),
						value: get(valueKey),
						disabled: disabledKey && get(disabledKey),
					};
				})
				.filter(
					({ value }, index, array) =>
						!array.slice(0, index).find(o => o.value == value),
				);
			Object.assign(this.fetchedOptions, newOptions);

			return this.optionsList;
		},
		emit() {
			const value = this.optionalValue ? this.inputValue : "";
			this.$emit("update:modelValue", value);
			this.$emit("valueChanged", value);
		},
	},
	computed: {
		optionalValue: {
			get() {
				//not optional
				if (!this.inputConfig.optional) return true;

				//uses another elements optional
				if (this.inputConfig?.optional_subscribe)
					return this.appStore.data[
						`input-from-json:${this.inputConfig.optional_subscribe}:optional-value`
					];

				//uses own optional
				return this.appStore.data[
					`input-from-json:${this.inputConfig.key}:optional-value`
				];
			},
			set(nv) {
				//allows other input from JSON's to subsribe to this inputs optional value
				if (this.inputConfig?.optional_subscribe) return;

				this.appStore.setData(
					`input-from-json:${this.inputConfig.key}:optional-value`,
					nv,
				);

				this.emit();
				this.vuelidateItem.$touch();
			},
		},
		isValidated() {
			return this.inputConfig?.validations !== false;
		},
		optionsList: {
			get() {
				const options = this.fetchedOptions?.length
					? this.fetchedOptions
					: this.inputConfig?.options ?? [];

				//if there isn't an option for the current value, try to add the option using providedOptions
				if (!options.some(o => o?.value ?? o == this.inputValue)) {
					const providedOption = this.providedOptions.find(
						o => o?.value ?? o == this.inputValue,
					);
					if (providedOption) options.push(providedOption);
				}

				return options;
			},
			set(value) {
				this.providedOptions.push(value);
			},
		},
		vuelidateItem: ({ vuelidateInstance, v$ }) =>
			vuelidateInstance || v$.validatedInputValue,
		validatedInputValue() {
			if (this.isValidated) return this.inputValue;
			return " ";
		},
		dynamicValidations() {
			//if optional only require additional validators if using input
			const wrap = validator =>
				this.inputConfig.optional
					? validator?.$message
						? VuelidateValidators.helpers.withMessage(
								validator.$message,
								VuelidateValidators.or(
									() => !this.optionalValue,
									validator,
								),
						  )
						: VuelidateValidators.or(
								() => !this.optionalValue,
								validator,
						  )
					: validator;

			//add dynamic validations
			let dynamicValidations = {};
			for (let validation in this.inputConfig?.validations) {
				const value = this.inputConfig?.validations[validation];
				switch (validation) {
					case "custom":
						if (allCustomValidators?.[value]) {
							const isCollectionOfValidators =
								typeof allCustomValidators[value] ==
									"function" ||
								Object.keys(allCustomValidators[value]).some(
									key => key.startsWith("$"),
								);
							//if function assign
							if (isCollectionOfValidators)
								dynamicValidations[value] = wrap(
									allCustomValidators[value],
								);
							//if collection of validation functions assign each
							else
								dynamicValidations = {
									...dynamicValidations,
									...Object.entries(
										allCustomValidators[value],
									).reduce(
										(all, [key, value]) => ({
											...all,
											[key]: wrap(value),
										}),
										{},
									),
								};
						}
						break;
					case "length":
						dynamicValidations.minLength = wrap(
							VuelidateValidators.minLength(value),
						);
						dynamicValidations.maxLength = wrap(
							VuelidateValidators.maxLength(value),
						);
						break;
					case "not":
						dynamicValidations.not = wrap(
							VuelidateValidators.helpers.withMessage(
								`Value cannot be ${value}`,
								VuelidateValidators.not(
									VuelidateValidators.sameAs(value),
								),
							),
						);
						break;
					default:
						//if not function validation then just add validation plain
						//else add function validation with value passed
						dynamicValidations[validation] = wrap(
							typeof VuelidateValidators[validation] == "function"
								? VuelidateValidators[validation](value)
								: VuelidateValidators[validation],
						);
						break;
				}
			}

			return dynamicValidations;
		},
	},
};
</script>
