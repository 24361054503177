const routes = [
	{
		path: "bookings/",
		name: "BookingList",
		component: () => import("../components/bookings/src/BookingList.vue"),
		children: [
			{
				path: ":bookingId",
				name: "Booking",
				redirect: () => ({ name: "BookingView" }),
				component: () =>
					import("../components/frame/src/BookingFrame.vue"),
				children: [
					{
						path: "view",
						name: "BookingView",
						component: () =>
							import(
								"../components/bookings/src/ViewBooking.vue"
							),
						props: true,
					},
					{
						path: "worker-profile",
						name: "BookingWorkerProfile",
						component: () =>
							import(
								"../components/bookings/src/BookingWorkerProfile.vue"
							),
						props: true,
					},
					{
						path: "edit",
						name: "BookingEdit",
						component: () =>
							import(
								"../components/bookings/src/EditBooking.vue"
							),
						props: true,
					},
					{
						path: "incidents",
						name: "IncidentsList",
						component: () =>
							import(
								"../components/bookings/src/IncidentsList.vue"
							),
						props: true,
						children: [
							{
								path: ":incidentId",
								name: "IncidentView",
								component: () =>
									import(
										"../components/bookings/src/IncidentView.vue"
									),
								props: true,
							},
						],
					},
					{
						path: "worker-policies",
						name: "BookingWorkerPolicies",
						component: () =>
							import(
								"../components/bookings/src/WorkerPolicies.vue"
							),
						props: true,
					},
					{
						path: "payment-additions",
						name: "PaymentAdditions",
						component: () =>
							import(
								"../components/bookings/src/PaymentAdditions.vue"
							),
						props: true,
					},
					{
						path: "invoices",
						name: "BookingInvoices",
						component: () =>
							import(
								"../components/bookings/src/BookingInvoices.vue"
							),
						props: true,
					},
					{
						path: "log",
						name: "BookingLog",
						component: () =>
							import("../components/bookings/src/BookingLog.vue"),
						props: true,
					},
				],
			},
		],
	},
	{
		path: "create-bookings",
		name: "BookingCreate",
		component: () =>
			import("../components/bookings/src/CreateBookings.vue"),
	},
	{
		path: "reserve-bookings",
		name: "BookingReserve",
		component: () =>
			import("../components/bookings/src/ReserveBookings.vue"),
	},
	{
		path: "incidents/",
		name: "Incidents",
		component: () => import("../components/bookings/src/IncidentsList.vue"),
		meta: {
			allowedTeams: [
				"admin",
				{
					team: "provider",
					with: ["manage_incidents"],
				},
			],
		},
	},
];

export default routes;
